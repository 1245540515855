import React, { useEffect, useRef, useState } from "react";
import styles from "./ChatPopUp.module.scss";
import useGeneralState from "../../hooks/useGeneralState";
import { GeneralState } from "../../types";
import Modal from "../modal/Modal";
import { useChatInterfaceModal } from "../../hooks/usePopUpModals";
import { ModalType } from "../../hooks/usePopUpModals";
import useChat from "../../store/chatStore";
import { ChatState } from "../../store/chatStore";
import UseMarkdown from "../../hooks/useMarkdown";
import { scrollPage } from "../../utils/scroll";
import { sendDisabled, stop } from "./svgs";
import { chatPopupStyle } from "../../injectedStyles/chatPopup";
import { globalStyles } from "../../globbalStyle";
import { close, checkSolid, copySolid, logoAlt, trigger } from "../../assets";
import EntryPage from "../entryPage/EntryPage";
import PromptBox from "../promptBox/PromptBox";

// Widget components
import Sources from "../widgets/Sources";

const CDN_URL = process.env.REACT_APP_CDN_URL;
const base = process.env.REACT_APP_URL;
const ast = process.env.REACT_APP_AST;
const pxa = process.env.REACT_APP_PXA;

export interface Props {
	client_id?: string;
	user_id: string;
	quick_prompts?: string[];
	first_name?: string;
	meta_data?: {};
	session_id?: string;
}

const ChatPopUp = ({
	client_id,
	user_id,
	quick_prompts,
	first_name,
	meta_data,
	session_id,
}: Props) => {
	const [copied, setCopied] = useState(false);
	const [randomPrompts, setRandomPrompts] = useState<string[]>([]);
	const stopStreamFlag = useRef(false);
	const abortControllerRef = useRef<AbortController | null>(null);
	const { theme } = useGeneralState((state: GeneralState) => state);
	const { isOpen, onClose } = useChatInterfaceModal((state: ModalType) => state);
	const { Messages, addMessage, toolFeedback, setToolFeedback } = useChat(
		(state: ChatState) => state
	);
	const [triggerClose, setTriggerClose] = useState(false);
	const textareaRef = useRef<HTMLTextAreaElement | null>(null);
	const scrollContainerRef = useRef<HTMLElement | null>(null);
	const [text, setText] = useState("");
	const [generating, setGenerating] = useState(false);
	const [streaming, setStreaming] = useState(false);
	const [loading, setLoading] = useState(false);
	console.log(streaming, "streaming");

	useEffect(() => {
		if (quick_prompts && quick_prompts.length > 0) {
			const shuffledPrompts = [...quick_prompts].sort(() => 0.5 - Math.random());
			setRandomPrompts(shuffledPrompts.slice(0, 4));
		}
	}, [quick_prompts]);

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setText(event.target.value);
	};

	useEffect(() => {
		const textarea = textareaRef.current;
		if (textarea) {
			textarea.style.height = "auto";
			textarea.style.height = `${textarea.scrollHeight}px`;
		}
	}, [text]);

	const handleClose = () => {
		setTriggerClose(true);
	};

	const stopStream = () => {
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
			abortControllerRef.current = null;
		}
		setGenerating(false);
		setStreaming(false);
		setLoading(false);
		setToolFeedback("");
		stopStreamFlag.current = true;
	};

	let intentId = "";
	let intentValue = "";
	let currentEvent = "";
	let currentData = "";
	let messageBuffer = "";

	const chatClive = async () => {
		setGenerating(true);
		setLoading(true);
		const basicAuth = "Basic " + btoa(ast + ":" + pxa);

		try {
			abortControllerRef.current = new AbortController();
			const response = await fetch(`${base}/chat-snapshot-stream`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: basicAuth,
				},
				body: JSON.stringify({
					sentence: text,
					partnerId: client_id ?? "-",
					userId: user_id || "-",
					sessionId: session_id ?? "-",
					ragEnabled: "true",
					permissions: ["super_admin"],
					metadata: meta_data ?? {},
				}),
				signal: abortControllerRef.current?.signal,
			});

			if (response.ok) {
				const reader = response.body && response.body.getReader();
				const decoder = new TextDecoder("utf-8");
				let partialMessage = "";

				if (reader) {
					setStreaming(true);
					stopStreamFlag.current = false;
					while (!stopStreamFlag.current) {
						const { done, value } = await reader.read();
						if (done) {
							setStreaming(false);
							break;
						}
						partialMessage += decoder.decode(value, { stream: true });
						const messages = partialMessage.split("\n\n");
						for (const message of messages.slice(0, -1)) {
							const lines = message.split("\n");

							for (const line of lines) {
								if (line.startsWith("event:")) {
									if (currentEvent) {
										handleEvent(currentEvent, currentData?.trim());
										currentEvent = "";
										currentData = "";
									}
									currentEvent = line?.replace("event: ", "")?.trim();
								} else if (line.startsWith("data:")) {
									currentData += line?.replace("data:", "")?.trim();
									if (currentEvent === "message_start") {
										setToolFeedback("");
										setLoading(false);
										updateMessageBuffer(line?.replace("data:", "")?.trim());
									} else if (currentEvent === "tool_feedback") {
										setToolFeedback(currentData?.trim());
										scrollPage();
									} else if (currentEvent === "end_stream") {
										setToolFeedback("");
										setLoading(false);
									} else if (currentEvent === "tool_response") {
										const widgetData = JSON.parse(
											line.replace("data:", "").trim()
										);

										const keys = Object.keys(widgetData);
										if (keys.length === 1) {
											const key = keys[0];
											const data = widgetData[key];
											if (
												key === "793695195" &&
												(data.organic || data.topStories)
											) {
												addMessage({
													responses: [<Sources data={data} />],
													type: "widget",
												});
											}
										}
										// End of Selection
										scrollPage(true);
									}
								}
							}
						}

						partialMessage = messages[messages.length - 1];
					}
				}
				if (currentEvent) {
					handleEvent(currentEvent, currentData?.trim());
				}
			} else {
				throw new Error("Failed to send message");
			}
		} catch (error) {
			if (error instanceof Error && error.name !== "AbortError") {
				setToolFeedback("");
			}
		} finally {
			setGenerating(false);
			setStreaming(false);
			setToolFeedback("");
		}
	};

	const handleEvent = (eventType: string, data: string) => {
		const formatted = data?.replace(/\\n/g, "<br/>");
		if (eventType === "start_stream") {
			handleMessageStart(formatted);
		} else if (eventType === "message_end") {
			handleMessageEnd();
		}
	};

	const handleMessageStart = (data: string) => {
		messageBuffer = data;
	};

	const handleMessageEnd = () => {
		messageBuffer = "";
		setGenerating(false);
		setLoading(false);
		setToolFeedback("");
	};

	const updateMessageBuffer = (data: string) => {
		scrollPage();
		messageBuffer = data;
		const formattedMessage = messageBuffer;

		const currentMessages = useChat.getState().Messages;
		const lastMessageIndex = currentMessages.length - 1;

		if (lastMessageIndex >= 0) {
			const lastMessage = currentMessages[lastMessageIndex];
			lastMessage.responses = lastMessage.responses || [];
			if (lastMessage.responses.length > 0) {
				lastMessage.responses[0].clive_response = formattedMessage?.trim();
			} else {
				lastMessage.responses.push({
					clive_response: formattedMessage?.trim(),
					tools_response: [],
				});
			}
			useChat.setState({ Messages: [...currentMessages] });
		} else {
			const updatedMessage = {
				responses: [
					{ clive_response: formattedMessage?.trim(), tools_response: [] },
					{ intent: intentValue, intentId },
				],
				type: "received",
			};
			addMessage(updatedMessage);
		}
	};

	const sendMessage = async () => {
		setText("");
		addMessage({
			prompt: text,
			type: "sent",
			fileName: "",
			fileUrl: "",
			imageUrl: "",
		});

		chatClive();
	};

	useEffect(() => {
		const scrollLastContentToTop = () => {
			const scroll = scrollContainerRef.current;

			if (scroll) {
				const lastElement = scroll.lastElementChild;
				if (lastElement) {
					const lastContentHeight = lastElement.clientHeight + 130;
					scroll.scrollTo({
						top: scroll.scrollHeight - lastContentHeight,
					});
				}
			}
		};

		scrollLastContentToTop();
	}, [Messages]);

	const handleCopyClick = async (textToCopy: string) => {
		await navigator.clipboard.writeText(textToCopy);
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 2000);
	};

	const content = (
		<section className={`main ${styles.main} ${styles[theme]} BaseFont`}>
			<style>{chatPopupStyle() + globalStyles()}</style>
			<div className={`chatContent ${styles.chatContent}`}>
				<section
					className={`header  ${styles.header} ${
						Messages?.length > 0 ? `headerAlt ${styles.headerAlt}` : ""
					}`}
				>
					{Messages?.length > 0 ? (
						<div className={`logoContainer ${styles.logoContainer}`}>
							<img
								src={`${CDN_URL}${trigger}`}
								alt="logo"
								className={`logo ${styles.logo}`}
							/>
							<div>
								<p className={`snapshot BaseFont2 ${styles.snapshot}`}>Snapshot</p>
								<p className={`poweredBy BaseFont2 ${styles.poweredBy}`}>Powered by Clive</p>
							</div>
						</div>
					) : (
						<p></p>
					)}
					<img
						src={`${CDN_URL}${close}`}
						alt="close"
						onClick={handleClose}
						className={`close ${styles.close}`}
					/>
				</section>
				{Messages?.length < 1 ? (
					<EntryPage
						firstName={first_name}
						randomPrompts={randomPrompts}
						setText={setText}
						textareaRef={textareaRef}
						text={text}
						generating={generating}
						sendMessage={sendMessage}
						handleChange={handleChange}
						sendDisabled={sendDisabled}
						stopStream={stopStream}
						stop={stop}
					/>
				) : (
					<>
						<section
							id="chatContainer"
							ref={scrollContainerRef}
							className={`chatContainer ${styles.chatContainer} ${styles[theme]} BaseFont2`}
						>
							{Messages.map((message: any, index: number) => {
								return (
									<div key={index} className={`chats123 ${styles.chats123}`}>
										{message.type !== "widget" ? (
											<>
												{message.prompt && (
													<div
														className={`promptGroupp ${styles.promptGroupp}`}
													>
														{message.prompt && (
															<div
																className={`promptWrapper ${
																	styles.promptWrapper
																} ${
																	index !== 0 &&
																	styles.promptWrapperAlt
																}`}
															>
																<p
																	className={`prompt BaseFont2 ${styles.prompt}`}
																>
																	{message.prompt}
																</p>
															</div>
														)}
													</div>
												)}

												{message?.responses?.length > 0 && (
													<>
														{(() => {
															try {
																const responseData =
																	message.responses[0];
																if (responseData?.clive_response) {
																	return (
																		<div
																			className={`responseAnim responseContainer ${styles.responseContainer}`}
																		>
																			<img
																				src={`${CDN_URL}${logoAlt}`}
																				alt="trigger"
																				className={`triggerImg ${styles.snapshot}`}
																			/>
																			<div
																				className={`responseAnim BaseFont2 ${styles.response}`}
																			>
																				<UseMarkdown
																					ClassName={`responseSnapshot responseAnim ${styles.response}`}
																					children={responseData?.clive_response?.trim()}
																				/>
																				<div
																					className={`copy ${styles.copy}`}
																					onClick={() =>
																						handleCopyClick(
																							responseData?.clive_response?.trim()
																						)
																					}
																				>
																					<img
																						src={
																							copied
																								? `${CDN_URL}${checkSolid}`
																								: `${CDN_URL}${copySolid}`
																						}
																						alt="clipboard"
																						className={`logo clipboard ${styles.clipboard}`}
																					/>
																				</div>
																			</div>
																		</div>
																	);
																}
															} catch (error) {
																//
															}
														})()}
													</>
												)}
											</>
										) : (
											// Render widget responses
											<div className={styles.widgetContainer}>
												{message.responses.map(
													(widget: any, widgetIndex: number) => (
														<React.Fragment key={widgetIndex}>
															{widget}
														</React.Fragment>
													)
												)}
											</div>
										)}
									</div>
								);
							})}
							{loading && (
								<div className={`feedbackContainer ${styles.feedbackContainer}`}>
										<img
											src={`${CDN_URL}${trigger}`}
											alt="logoAlt"
											className={`logoLoading ${styles.logoLoading}`}
										/>
							
									<p className={`feedback ${styles.feedback}`}>{toolFeedback}</p>
								</div>
							)}
						</section>
						<section className={`promptBoxChat ${styles.promptBoxChat}`}>
							<PromptBox
								text={text}
								generating={generating}
								sendMessage={sendMessage}
								stopStream={stopStream}
								handleChange={handleChange}
								textareaRef={textareaRef}
							/>
							<p className={`poweredByFooter BaseFont2 ${styles.poweredBy}`}>Powered by CLIVE AI</p>
						</section>
					</>
				)}
			</div>
		</section>
	);

	return (
		<Modal
			triggerClose={triggerClose}
			setTriggerClose={setTriggerClose}
			isOpen={isOpen}
			onClose={onClose}
			content={content}
		/>
	);
};

export default ChatPopUp;
