export const preprocessChildren = (input: string) => {
	if (typeof input !== "string") return;
	
	// Convert markdown to HTML
	const withoutBold = input?.replace(/\*\*(.*?)\*\*/g, "<strong style='color: #111'>$1</strong>");
	const withoutItalic = withoutBold?.replace(/_([^_]+)_/g, "<em>$1</em>");
	const withStrikethrough = withoutItalic?.replace(/~~([^~]+)~~/g, "<del>$1</del>");
	const withInlineCode = withStrikethrough?.replace(/`([^`]+)`/g, "<code>$1</code>");
	const withHeaders = withInlineCode?.replace(/^#{1,6}\s+(.*)$/gm, (match, text) => {
	  const level = match?.trim().length;
	  return `<h${level}>${text}</h${level}>`;
	});
	const withLists = withHeaders?.replace(/^\s*-\s+(.*)$/gm, "<ul><li>$1</li></ul>");
	const withBlockquotes = withLists?.replace(/^\s*>\s+(.*)$/gm, "<blockquote>$1</blockquote>");
	const markdownToHtml = withBlockquotes?.replace(
	  /\[([^\]]+)\]\(([^)]+)\)/g,
	  '<a href="$2" target="_blank" rel="noopener noreferrer" class="readMore">$1</a>'
	);
	const htmlWithSingleBr = markdownToHtml?.replace(/\\n/g, "<br/>");
	const htmlWithSingleBrBr = htmlWithSingleBr?.replace(/\n/g, "<br/>");
	const htmlWithDoubleBr = htmlWithSingleBrBr?.replace(/(<br\/>)+/g, "<br/><br/>");
  
	return htmlWithDoubleBr;
  };
  