import React, { useState, useEffect } from "react";
import styles from "./styles/Sources.module.scss";
import truncateText from "../../utils/truncate";
import { scrollPage } from "../../utils/scroll";

interface SourceData {
	favicon?: string;
	title?: string;
	snippet?: string;
	link: string;
	faviconFailed?: boolean;
}

const defaultSource: SourceData = {
	title: "Source",
	snippet: "Loading source details...",
	link: "#",
	faviconFailed: false,
};

const Sources = ({ data }: { data: { organic: any[] } }) => {
	const [sources, setSources] = useState<SourceData[]>([]);

	useEffect(() => {
		const defaultSources = Array(3).fill(defaultSource);
		setSources(defaultSources);

		if (data?.organic) {
			const processedSources = data.organic.slice(0, 3).map((item) => {
				const url = new URL(item.link);
				const faviconUrl = `https://www.google.com/s2/favicons?domain=${url.hostname}`;

				return {
					favicon: faviconUrl,
					title: item.title || defaultSource.title,
					snippet: item.snippet || defaultSource.snippet,
					link: item.link,
					faviconFailed: false,
				};
			});

			setSources(processedSources);
			scrollPage(true);
		}
	}, [data]);

	return (
		<main className={`${styles.main} responseAnim`}>
			<div className={styles.container}>
		
					<h1 className={`${styles.title} sources`}>Sources</h1>
		
				<section className={styles.sources}>
					{sources.map((source, index) => (
						<div key={index} className={styles.card}>
							<div className={styles.content}>
								<div className={styles.head}>
									{!source.faviconFailed && source.favicon && (
										<img
											src={source.favicon}
											alt=""
											width={20}
											height={20}
											style={{ borderRadius: "50%" }}
											onError={() => {
												setSources((prevSources) => {
													const newSources = [...prevSources];
													newSources[index] = {
														...newSources[index],
														faviconFailed: true,
													};
													return newSources;
												});
											}}
										/>
									)}
									<p
										onClick={() => window.open(source.link, "_blank")}
										style={{ cursor: "pointer" }}
									>
										{truncateText(source.title || "", 20)}
									</p>
								</div>
								<p className={styles.snippet}>
									{truncateText(source.snippet || "", 50)}
								</p>
							</div>
							<p
								role="button"
								onClick={() => window.open(source.link, "_blank")}
								className={styles.visit}
							>
								visit
							</p>
						</div>
					))}
				</section>
			</div>
		</main>
	);
};

export default Sources;
