import React from "react";
import styles from "./PromptBox.module.scss";
import { sendDisabled, stop } from "../chatPopUp/svgs";

interface Props {
	text: string;
	generating: boolean;
	sendMessage: () => void;
	stopStream: () => void;
	handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
	textareaRef: React.RefObject<HTMLTextAreaElement>;
}

const PromptBox = ({
	text,
	generating,
	sendMessage,
	stopStream,
	handleChange,
	textareaRef,
}: Props) => {
	return (
		<div className={`bottomA ${styles.bottomA}`}>
			<section className={`textArea ${styles.textArea}`}>
				<textarea
					rows={1}
					id="prompt_box"
					ref={textareaRef}
					onKeyDown={(e) => {
						if (
							e.key === "Enter" &&
							!e.shiftKey &&
							text.trim().length > 0 &&
							!generating
						) {
							e.preventDefault();
							sendMessage();
						}
					}}
					value={text}
					placeholder={"Ask Snapshot Anything"}
					className={`text ${styles.text}`}
					style={{ fontFamily: "CircularStd, sans-serif" }}
					onChange={handleChange}
				/>
				<section className={`actionBtns ${styles.actionBtns}`}>
					{!generating && (
						<button
							disabled={text.trim().length < 1}
							onClick={sendMessage}
							className={`sendBtn ${styles.sendBtn} ${
								text.trim().length < 1 && `sendBtnAlt ${styles.sendBtnAlt}`
							}`}
						>
							{sendDisabled}
						</button>
					)}

					{generating && (
						<button onClick={stopStream} className={`sendBtn ${styles.sendBtn}`}>
							{stop}
						</button>
					)}
				</section>
			</section>
		</div>
	);
};

export default PromptBox;
