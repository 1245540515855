import React, { useEffect } from "react";
import styles from "./ChatInterface.module.scss";
import useGeneralState from "../../hooks/useGeneralState";
import { GeneralState } from "../../types";
import { useChatInterfaceModal } from "../../hooks/usePopUpModals";
import { ModalType } from "../../hooks/usePopUpModals";
import { trigger } from "../../assets";
import { globalStyles } from "../../globbalStyle";
import { chatInterfaceStyle } from "../../injectedStyles/chatInterfaceStyle";
const CDN_URL = process.env.REACT_APP_CDN_URL;

export interface Props {
	client_id?: string;
	quick_prompts?: string[];
	theme?: string;
	first_name?: string;
	meta_data?: {};
	button_size?: string;
}

const ChatInterface = ({ theme, button_size }: Props) => {
	const { setTheme } = useGeneralState((state: GeneralState) => state);
	const { onOpen } = useChatInterfaceModal((state: ModalType) => state);

	useEffect(() => {
		setTheme(theme ?? "light");
		(window as any).openSnapshot = () => {
			onOpen();
		};
	}, [onOpen, setTheme, theme]);

	return (
		<main className={`main ${styles.main}`}>
			<style>{chatInterfaceStyle() + globalStyles()}</style>
			<button
				onClick={onOpen}
				className={`trigger ${styles.trigger} ${
					button_size === "sm"
						? styles.sm
						: button_size === "md"
						? styles.md
						: button_size === "lg"
						? styles.lg
						: ""
				}`}
			>
				<img
					src={`${CDN_URL}${trigger}`}
					alt="trigger"
					className={`triggerImg ${styles.triggerImg}`}
				/>
			</button>
		</main>
	);
};

export default ChatInterface;
